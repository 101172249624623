<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="w-100 cart">
			<b-card-group id="home">
				<b-card
					id="cartBackground"
					class="border-0 bg-transparent mb-0">
					<div
						class="row"
						style="min-height: 270px">
						<div class="col my-auto">
							<div :class="!['xs', 'sm'].includes(windowWidth) ? 'container' : ''">
								<div
									class="pull-left px-3"
									style="background-color: rgba(20, 20, 20, 0.3)">
									<div
										style="font-weight: 600;"
										class="text-white big-label mt-5">
										{{ translate('become_an_affiliate') }}
									</div>
									<div
										style="font-weight: 200;"
										class="text-white p-2">
										{{ translate('become_an_affiliate_description') }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</b-card>
			</b-card-group>
		</div>
		<div class="container">
			<div
				:class="['xs'].includes(windowWidth) ? 'mt-2' : 'mt-3'"
				class="row mb-5">
				<div class="col-12 mx-auto">
					<div class="row">
						<div class="col-12">
							<flow-info-group>
								<flow-info-section
									:key="`1-0`"
									:collapse="false"
									:compact="false"
									hide-edit
									init-edit
									:title="`${translate('personal_information')}`"
									hide-controls
									active-color="dark"
									hide-cancel
									override-edit
									no-inner-spacing>
									<personal-information-edit
										@invalidRequest="handleInvalidRequest"
										@cantUseSelectedSponsor="redirectToStore"
										@saved="savePersonalInfo" />
								</flow-info-section>
							</flow-info-group>
						</div>
					</div>
				</div>
			</div>
		</div>
		<autoship-info-modal
			:show-modal="showAutoshipInfoModal"
			@confirm="fillAutoshipInfo"
			@cancel="closeAutoshipModal"
			@close="closeAutoshipModal" />
	</div>
</template>
<script>
import DocumentTitle from '@/mixins/DocumentTitle';
import FlowInfoGroup from '@/components/FlowInfo/Group';
import FlowInfoSection from '@/components/FlowInfo/Section';
import WindowSizes from '@/mixins/WindowSizes';
import EarlyLaunch from '@/mixins/EarlyLaunch';
import {
	Cart, Grids, Profile, Purchase, Validations, BecomeAffiliate,
} from '@/translations';
import PersonalInformationEdit from './components/PersonalInformation/Edit';
import Captcha from '@/mixins/Captcha';
import AutoshipInfoModal from '@/views/BecomeAffiliate/components/AutoshipInfoModal';
import { NO_AUTOSHIP_COUNTRIES } from '@/settings/Autoship';

export default {
	name: 'BecomeAffiliate',
	messages: [Cart, Grids, Profile, Purchase, Validations, BecomeAffiliate],
	components: {
		AutoshipInfoModal,
		FlowInfoGroup,
		FlowInfoSection,
		PersonalInformationEdit,
	},
	mixins: [DocumentTitle, WindowSizes, Captcha, EarlyLaunch],
	data() {
		return {
			showAutoshipInfoModal: false,
			noAutoshipCountries: NO_AUTOSHIP_COUNTRIES,
		};
	},
	methods: {
		handleInvalidRequest() {
			console.log('Handle invalid request');
		},
		redirectToStore() {
			this.$router.replace({ name: 'Store' });
		},
		savePersonalInfo(form) {
			const alert = new this.$Alert();
			alert.success(this.translate('success_title'), this.translate('success_text'), true).then(() => {
				const { username, password } = form;

				this.getCaptchaToken('login').then((response) => {
					this.$user.login(username, password, null, response).then(() => {
						if (this.earlyLaunch || (this.noAutoshipCountries.includes(this.country) && !this.isJns())) {
							this.$router.push({ name: 'MainHome' });
						} else {
							this.showAutoshipInfoModal = true;
						}
					});
				});
			});
		},
		fillAutoshipInfo() {
			this.showAutoshipInfoModal = false;
			this.$router.push({ name: 'AffiliateAutoshipInfo' });
		},
		closeAutoshipModal() {
			this.showAutoshipInfoModal = false;
			this.$router.push({ name: 'MainHome' });
		},
	},
};
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat');
.big-label {
	font-size: 55px;
}
@media only screen and (max-width: 575px) {
	#cartBackground .big-label {
		font-family: 'Montserrat', sans-serif !important;
		font-size: 45px;
	}
}
.cart  {
	font-family: 'Montserrat', sans-serif !important;
	font-weight: 600 !important;
	margin-top: -5px;
}
</style>
